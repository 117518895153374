/* 
---------------------------------------------
preloader
--------------------------------------------- 
*/

.loader-wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 99999;
	margin: 0;
	background-image: linear-gradient(127deg, $gradientDark 0%, $gradientLight 91%);
	display: flex;
	justify-content: center;
	align-items: center;

	.center {
		width: 90px;
		height: 90px;
		position: relative; 
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.dot {
			width: 8px; 
			animation: dot 1200ms cubic-bezier(0.91, 1.42, 0.87, 0.99);
			animation-fill-mode: both;
			animation-iteration-count: infinite;
			height: 8px;
			border-radius: 50%;
			background-color: #fff;
			margin:1px;

			&.dot-two {
				animation-delay: 150ms;
			}
			&.dot-three {
				animation-delay: 300ms;
			}
			&.dot-four{
				animation-delay: 450ms;
			}
			&.dot-five{
				animation-delay: 600ms;
			}
		}
	}
}

@keyframes dot {
	0% {
		transform: translateY(-10px);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(-10px);
	}
}