/* 
---------------------------------------------
welcome area
--------------------------------------------- 
*/

.welcome-area {
	min-height: 637px;
	height: auto;
	position: relative;

	.welcome-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.85;
			background-image: linear-gradient(127deg, $gradientDark 0%, $gradientLight 91%);
			z-index: 3;
		}

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 50%;
			bottom: 0px;
			left: 0px;
			right: 0px;
			background: none;
			z-index: 6;
		}

		img {
			position: absolute;
			width: 100%;
			height: auto;
			bottom: -1px;
			left: 0px;
			right: 0px;
			z-index: 5;
		}
	}

	.welcome-content {
		position: absolute;
		width: 100%;
		top: 50%;
		left: 0;
		z-index: 11;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);

		h1 {
			@include font($normal, 36px);
			line-height: 46px;
			letter-spacing: 1.4px;
			color: #fff;
			margin-bottom: 30px !important;
		}

		p {
			@include font($normal, 16px);
			line-height: 28px;
			left: 0.53px;
			color: #fff;
			margin-bottom: 30px;
		}

		.apps {
			padding-top: 30px;

			.app-item {
				display: block;
				float: right;
				margin-bottom: 30px;
				@include transition(.3s);

				&:hover {
					margin-top: -10px;
					padding-bottom: 10px;
				}

				.icon {
					box-shadow: 0 2px 48px 0 rgba(0,0,0,0.06);
				}
			}
		}
	}
}

@media (max-width : 991px) {
	.welcome-area {

		.welcome-content {
			position: relative;
			-webkit-transform: translateY(0%) !important;
			transform: translateY(0%) !important;
			padding-top: 100px;

			h1 {
				font-size: 26px !important;
				line-height: 36px !important;
			}

			.apps {
				margin-top: 30px;
				
				.app-item {
					float: left;
				}
			}
		}
	}
}