/* ----------------------------------------------------------------------------------------
Template: Beapp - Mobile App Development Agency HTML5 Template
Version: 1.1.0
Author: tempload - support@tempload.com
 -----------------------------------------------------------------------------------------*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. preloader
03. global styles
04. buttons
05. header
06. welcome area
07. home
08. blog
09. our work
10. aside
11. contact
12. footer
--------------------------------------------- */


@import 'mixins';

@import 'theme-options';

@import 'reset';

@import 'loader';

@import 'global';

@import 'buttons';

@import 'header';

@import 'welcome';

@import 'home';

@import 'blog';

@import 'our-work';

@import 'aside';

@import 'contact';

@import 'footer';