/* 
---------------------------------------------
contact
--------------------------------------------- 
*/

.map-wrapper {
	position: relative;
	width: 100%;
	height: 365px;
	margin-bottom: 60px;

	.map-canvas {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.contact-info {
		height: 365px;
		background-image: linear-gradient(127deg, $gradientDark 0%, $gradientLight 91%);
		padding: 40px;
		padding-top: 55px;
		color: #fff;

		.item {
			overflow: hidden;
			margin-bottom: 30px;

			i {
				float: left;
				font-size: 16px;
				width: 20px;
				margin-right: 10px;
				margin-top: 5px;
			}

			span {
				float: left;
				width: calc(100% - 30px);
				width: -webkit-calc(100% - 30px);
				font-size: 14px;

				a {
					color: #fff;
				}
			}
		}

		.social {
			overflow: hidden;
			margin-top: 5px;
			text-align: center;

			li {
				display: inline;
				font-size: 22px;
				margin-right: 7px;
				margin-left: 7px;

				a {
					color: #fff;
				}
			}
		}
	}
}

.contact-text {
	@include font($normal, 14px);
	color: $textDark;
	letter-spacing: 0.6px;
	line-height: 26px;

	p {
		margin-bottom: 28px;
	}
}

.contact-form {
	input, textarea {
		border: 1px solid #eee;
		width: 100%;
		height: 50px;
		outline: none;
		padding-left: 20px;
		padding-right: 20px;
		@include transition(.3s);
		@include radius(4px);
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin-bottom: 30px;

		&:focus {
			border: 1px solid $primaryColor;
			padding-left: 30px;
		}
	}

	textarea {
		height: 150px;
		resize: none;
		padding: 20px;
	}
}

@media (max-width : 991px) {
	.map-wrapper {
		height: auto;

		.map-canvas {
			position: relative;
			height: 365px;
			margin-bottom: 30px;
		}
	}
}