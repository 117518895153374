/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.header-area {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 100;
	height: 100px;
	@include transition(.3s);

	.main-nav {

		.logo {
			float: left;
			margin-top: 30px;
			@include transition(.3s);

			img {
				@include transition(.3s);
			}
		}

		.dark-logo {
			display: none;
		}		

		.nav {
			float: right;
			margin-top: 30px;
			@include transition(.3s);

			li {
				margin-left: 35px;

				a {
					display: block;
					@include font($bold, 14px);
					color: #FFFFFF;
					@include transition(.3s);
					height: 36px;
					line-height: 36px;
					border: transparent;

					&:hover {
						color: $textLight;
					}

					&.btn-nav-line {
						border: 1px solid #fff;
						width: 140px;
						height: 36px;
						line-height: 36px;
						text-align: center;
						@include radius(100px);
						@include transition(.3s);

						&:hover {
							background: #fff;
							color: $primaryColor;
						}
					}
				}
			}
		}

		.menu-trigger {
			cursor: pointer;
			display: block;
			position: absolute;
			top: 23px;
			width: 32px;
			height: 40px;
			text-indent: -9999em;
			z-index: 99;
			right: 40px;
			display: none;

			span,
			span:before,
			span:after {
				-moz-transition: all 0.4s;
				-o-transition: all 0.4s;
				-webkit-transition: all 0.4s;
				transition: all 0.4s;
				background-color: $headingDark;
				display: block;
				position: absolute;
				width: 26px;
				height: 2px;
				left: 0;
			}

			span:before,
			span:after {
				-moz-transition: all 0.4s;
				-o-transition: all 0.4s;
				-webkit-transition: all 0.4s;
				transition: all 0.4s;
				background-color: $headingDark;
				display: block;
				position: absolute;
				width: 30px;
				height: 2px;
				left: 0;
			}

			span:after {
				width: 22px;
			}


			span:before,
			span:after {
				content: "";
			}

			span {
				top: 16px;

				&:before {
					-moz-transform-origin: 33% 100%;
					-ms-transform-origin: 33% 100%;
					-webkit-transform-origin: 33% 100%;
					transform-origin: 33% 100%;
					top: -10px;
					z-index: 10;
				}

				&:after {
					-moz-transform-origin: 33% 0;
					-ms-transform-origin: 33% 0;
					-webkit-transform-origin: 33% 0;
					transform-origin: 33% 0;
					top: 10px;
				}
			}

			&.active span,
			&.active span:before,
			&.active span:after {
				background-color: transparent;
				width: 100%;
			}

			&.active span:before {
				-moz-transform: translateY(6px) translateX(1px) rotate(45deg);
				-ms-transform: translateY(6px) translateX(1px) rotate(45deg);
				-webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
				transform: translateY(6px) translateX(1px) rotate(45deg);
				background-color: $headingDark;
			}

			&.active span:after {
				-moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
				-ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
				-webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
				transform: translateY(-6px) translateX(1px) rotate(-45deg);
				background-color: $headingDark;
			}
		}
	}

	&.header-sticky {
		background: #fff;
		height: 80px;
		box-shadow: 0 2px 28px 0 rgba(0,0,0,0.06);

		.logo {
			margin-top: 22px;
		}

		.light-logo {
			display: none;
		}

		.nav {
			margin-top: 20px !important;

			li {
				a {
					color: $headingDark;

					&:hover {
						color: $headingDark;
					}

					&.btn-nav-line {
						border: 1px solid $primaryColor;
						color: $primaryColor;
					}
				}
			}
		}
	}
}

@media (max-width : 1200px) {
	.header-area {
		.main-nav {
			.nav {
				li {
					margin-left: 15px !important;
				}
			}
		}
	}
}

@media (max-width : 991px) {
	.header-area {
		background: #fff;
		height: 80px;
		box-shadow: 0 2px 28px 0 rgba(0,0,0,0.06);

		.container {
			padding: 0px;
		}

		.logo {
			margin-top: 22px !important;
			margin-left: 30px;
		}

		.light-logo {
			display: none !important;
		}

		.dark-logo {
			display: block !important;
		}

		.menu-trigger {
			display: block !important;
		}

		.main-nav {
			overflow: hidden;

			.nav {
				float: none;
				width: 100%;
				margin-top: 80px !important;
				display: none;
				@include transition(0s);

				li {
					width: 100%;
					margin-left: 15px !important;
					background: #fff;
					margin: 0px !important;
					border-bottom: 1px solid #f5f5f5;

					a {
						height: 50px !important;
						line-height: 50px !important;
						padding: 0px !important;
						padding-left: 30px !important;
						border: none !important;
						background: #fff !important;
						color: $headingDark !important;

						&:hover {
							background: #f5f5f5 !important;
						}

						&.btn-nav-line {
							border: none !important;
							width: 100%;
							height: 50px;
							line-height: 50px;
							text-align: left;
							@include radius(0px);
						}						
					}
				}
			}
		}
	}
}

@media (min-width : 992px) {
	.header-area {
		.main-nav {
			.nav {
				display: flex !important;
			}
		}
	}
}